export interface IFMenuBase {
    key: string;
    title: string;
    icon?: string;
    component?: string;
    query?: string;
    requireAuth?: string;
    route?: string;
    /** 是否登录校验，true不进行校验（访客） */
    login?: boolean;
}

export interface IFMenu extends IFMenuBase {
    subs?: IFMenu[];
}

const menus: {
    menus: IFMenu[];
    others: IFMenu[] | [];
    [index: string]: any;
} = {
    menus: [
        // 菜单相关路由
        // { key: '/app/dashboard/index', title: '首页', icon: 'mobile', component: 'Dashboard' },
        {
            key: '/app/visit',
            title: '访问注册统计',
            component: 'Visit',
        },
        {
            key: '/app/statistics',
            title: '渠道运营统计',
            icon: 'rocket',
            component: 'Statistics',
        },
        // {
        //     key: '/app/member',
        //     title: '线下会员',
        //     component: 'Member',
        // },
        { key: '/app/Promote-message',  title: '推广信息', icon: 'mobile', component: 'PromoteMessage' },

  /*       {
             key: '/app/animation',
             title: '动画',
             icon: 'rocket',
             subs: [
                 {
                     key: '/app/animation/basicAnimations',
                     title: '基础动画',
                     component: 'BasicAnimations',
                 },
                 {
                     key: '/app/animation/exampleAnimations',
                     title: '动画案例',
                     component: 'ExampleAnimations',
                 },
             ],
         },
         {
             key: '/app/table',
             title: '表格',
             icon: 'copy',
             subs: [
                 { key: '/app/table/basicTable', title: '基础表格', component: 'BasicTable' },
                 { key: '/app/table/advancedTable', title: '高级表格', component: 'AdvancedTable' },
                 {
                     key: '/app/table/asynchronousTable',
                     title: '异步表格',
                     component: 'AsynchronousTable',
                 },
             ],
         },
         {
             key: '/app/chart',
             title: '图表',
             icon: 'area-chart',
             subs: [
                 { key: '/app/chart/echarts', title: 'echarts', component: 'Echarts' },
                 { key: '/app/chart/recharts', title: 'recharts', component: 'Recharts' },
             ],
         },
         {
             key: '/subs4',
             title: '页面',
             icon: 'switcher',
             subs: [
                 { key: '/login', title: '登录' },
                 { key: '/404', title: '404' },
             ],
         },
         {
             key: '/app/auth',
             title: '权限管理',
             icon: 'safety',
             subs: [
                 { key: '/app/auth/basic', title: '基础演示', component: 'AuthBasic' },
                 {
                     key: '/app/auth/routerEnter',
                     title: '路由拦截',
                     component: 'RouterEnter',
                     requireAuth: 'auth/testPage',
                 },
             ],
         },
         {
             key: '/app/cssModule',
             title: 'cssModule',
             icon: 'star',
             component: 'Cssmodule',
         },
         {
             key: '/app/extension',
             title: '功能扩展',
             icon: 'bars',
             subs: [
                 {
                     key: '/app/extension/queryParams',
                     title: '问号形式参数',
                     component: 'QueryParams',
                     query: '?param1=1&param2=2',
                 },
                 {
                     key: '/app/extension/visitor',
                     title: '访客模式',
                     component: 'Visitor',
                     login: true,
                 },
                 {
                     key: '/app/extension/multiple',
                     title: '多级菜单',
                     subs: [
                         {
                             key: '/app/extension/multiple/child',
                             title: '多级菜单子菜单',
                             subs: [
                                 {
                                     key: '/app/extension/multiple/child/child',
                                     title: '多级菜单子子菜单',
                                     component: 'MultipleMenu',
                                 },
                             ],
                         },
                     ],
                 },
                 {
                     key: '/app/extension/env',
                     title: '环境配置',
                     component: 'Env',
                 },*/
    ],
    others: [], // 非菜单相关路由

};

export default menus;
